var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"pc_ehp"},[_c('div',{staticClass:"banner-box"},[_c('div',{staticClass:"banner-boxs"},[_c('div',{staticClass:"banner-name"},[_vm._v("易策EHP医院绩效软件系统")]),_c('div',{staticClass:"banner-little-name"},[_vm._v(" 以预算为基础，工作量（DRGs&DIP）为依据，以绩效管理为抓手-促进医院“提质增效” ")]),_c('div',{staticClass:"banner-bnt",on:{"click":_vm.openPDF}},[_vm._v("下载资料")])])]),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal"}],staticClass:"ehp-framework"},[_vm._m(0),_c('implementationMod',{attrs:{"implementationData":_vm.implementationData},on:{"relationUs":_vm.relationUs}})],1),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal"}],staticClass:"ehp-trait"},[_vm._m(1),_vm._m(2),_vm._m(3)]),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal"}],staticClass:"ehp-framework"},[_vm._m(4),_vm._m(5),_c('img',{staticClass:"ehpImg7",attrs:{"src":require("../assets/img/ehpImg7.png"),"alt":""}})]),_c('serviceAcc',{attrs:{"bg":2}}),_c('expertItemMod'),_c('relation',{attrs:{"open":_vm.open},on:{"openFun":_vm.openFun}})],1),_c('mobileEhp',{staticClass:"mobile_ehp",attrs:{"implementationData":_vm.implementationData}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ehp-i"},[_c('div',{staticClass:"flex title2"},[_c('img',{staticClass:"titleIcon1",attrs:{"src":require("../assets/img/titleIcon1.png")}}),_vm._v(" 项目介绍 "),_c('img',{staticClass:"titleIcon1 rotate180",attrs:{"src":require("../assets/img/titleIcon1.png")}})]),_c('div',{staticClass:"flex mes2 ehpMes2"},[_c('img',{attrs:{"src":require("../assets/img/titleIcon2.png")}})]),_c('div',{staticClass:"flex flexSB ehp-i-div"},[_c('img',{attrs:{"src":require("../assets/img/ehpImg1.png"),"alt":""}}),_c('div',{staticClass:"ehp-i-td"},[_c('p',[_vm._v(" 基于工作量+业绩的综合效率预算的绩效分配模式，匹配最新的医保支付模式，通过配置自动采集有效数据，实现自由可控的绩效统计与核算。数据可视化大屏功能，充分发挥数据价值，帮助医院管理者快速找到影响医疗质量与运营效率的因素，高效精细化运营管理。 ")]),_c('p',[_vm._v(" 易策EHP医院绩效软件系统包括:绩效数据核算模块、预算模块、业务科主任护士长模块、绩效考核模块、绩效发放模块、重点指标监控模块、绩效数据群体分析模块、绩效数据个体分析模块、月度经营分析报告、核算数据建立台账管理十大模块。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex title2"},[_c('img',{staticClass:"titleIcon1",attrs:{"src":require("../assets/img/titleIcon1.png")}}),_vm._v(" 产品特点 "),_c('img',{staticClass:"titleIcon1 rotate180",attrs:{"src":require("../assets/img/titleIcon1.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex mes2"},[_c('img',{attrs:{"src":require("../assets/img/titleIcon2.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flexS ehp-traitDiv"},[_c('img',{staticClass:"ehp-traitDiv-img",attrs:{"src":require("../assets/img/ehpImg6.png")}}),_c('div',{staticClass:"ehp-traitDiv-text"},[_c('div',{staticClass:"flex flexS"},[_c('img',{attrs:{"src":require("../assets/img/review_13.png")}}),_vm._v("支持医疗集团绩效考核 ")]),_c('div',{staticClass:"flex flexS"},[_c('img',{attrs:{"src":require("../assets/img/review_13.png")}}),_vm._v("实现绩效管理全流程管控 ")]),_c('div',{staticClass:"flex flexS"},[_c('img',{attrs:{"src":require("../assets/img/review_13.png")}}),_vm._v("以预算为基础，动态反馈 ")]),_c('div',{staticClass:"flex flexS"},[_c('img',{attrs:{"src":require("../assets/img/review_13.png")}}),_vm._v("智能监控数据，纠正纠偏 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex title2"},[_c('img',{staticClass:"titleIcon1",attrs:{"src":require("../assets/img/titleIcon1.png")}}),_vm._v(" 绩效软件系统架构 "),_c('img',{staticClass:"titleIcon1 rotate180",attrs:{"src":require("../assets/img/titleIcon1.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex mes2"},[_c('img',{attrs:{"src":require("../assets/img/titleIcon2.png")}})])
}]

export { render, staticRenderFns }
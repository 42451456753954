<template>
  <div>
    <div class="m_banner">
      <div class="m_banner_text">易策EHP医院绩效软件系统</div>
      <div class="m_banner_i"></div>
      <div class="m_banner_text2">以预算为基础，工作量（DRGs&DIP）为依据，<br />以绩效管理为抓手-促进医院“提质增效”</div>
    </div>
    <div class="ehp_m_box">
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">项目介绍</span>
        <span class="index_title_after"></span>
      </div>
      <img class="ehp_m_img" src="../assets/img/ehpImg1.png" alt="">
      <div class="ehp_m_introduce">
        <p>
          基于工作量+业绩的综合效率预算的绩效分配模式，匹配最新的医保支付模式，通过配置自动采集有效数据，实现自由可控的绩效统计与核算。数据可视化大屏功能，充分发挥数据价值，帮助医院管理者快速找到影响医疗质量与运营效率的因素，高效精细化运营管理。
        </p>
        <p>
          易策EHP医院绩效软件系统包括:绩效数据核算模块、预算模块、业务科主任护士长模块、绩效考核模块、绩效发放模块、重点指标监控模块、绩效数据群体分析模块、绩效数据个体分析模块、月度经营分析报告、核算数据建立台账管理十大模块。
        </p>
      </div>
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">{{ implementationData.title }}</span>
        <span class="index_title_after"></span>
      </div>
      <div class="modern_m_trait_list">
        <div class="modern_m_trait_item" v-for="(item, index) in implementationData.list" :key="index">
          <img class="modern_m_trait_img" :src="item.img" alt="">
          <div class="modern_m_trait_i">
            <div class="modern_m_trait_name">{{ item.name }}</div>
            <div class="modern_m_trait_introduce">{{ item.introduce }}</div>
          </div>
        </div>
      </div>
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">产品特点</span>
        <span class="index_title_after"></span>
      </div>
      <img class="ehp_m_img2" src="../assets/img/ehpImg6.png" />
      <div class="ehp_m_list">
        <div class="ehp_m_item">
          <img src="../assets/img/review_13.png" />支持医疗集团绩效考核
        </div>
        <div class="ehp_m_item">
          <img src="../assets/img/review_13.png" />实现绩效管理全流程管控
        </div>
        <div class="ehp_m_item">
          <img src="../assets/img/review_13.png" />以预算为基础，动态反馈
        </div>
        <div class="ehp_m_item">
          <img src="../assets/img/review_13.png" />智能监控数据，纠正纠偏
        </div>
      </div>
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">绩效软件系统架构</span>
        <span class="index_title_after"></span>
      </div>
      <img class="ehp_m_img3" src="../assets/img/ehpImg7.png" alt="" />
      <div class="ehp_m_but">
        <span @click="is_big = true">点击查看大图</span>
      </div>
      <div class="ehp_m_big" v-if="is_big" @click="is_big = false">
        <img src="../assets/img/ehpImg7.png" />
      </div>
    </div>
    <div class="serve_m_box">
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">服务案例</span>
        <span class="index_title_after"></span>
      </div>
      <div class="index_subhead">易策医管集团已累计为超过<span style="color: #2D43F2;font-weight: 500">800+</span>家医院提供优质服务</div>
      <div class="serve_m_type">
        <div
          :class="['serve_m_type_item', isType == item.type ? 'serve_m_type_isItem' : '']"
          v-for="(item, index) in hisTypeList"
          :key="index" 
          @click="changeTab(item.type)"
        >
          {{ item.value }}
        </div>
      </div>
      <div class="serve_m_hospital">
        <div class="serve_m_hospital_item" v-for="(item, index) in hisList2" :key="index" @click="getDetails(item.hospitalId)">
          <img class="serve_m_hospital_img" :src="item.hospitalLogo" />
          <div class="serve_m_hospital_name">{{ item.hospitalName }}</div>
        </div>
      </div>
      <div class="index_more" @click="stretch">{{ hisShow ? '收起' : '查看更多' }}</div>
    </div>
  </div>
</template>

<script>
  import { getHospitalList } from "@/api/index";
  export default {
    props: ['implementationData'],
    data() {
      return {
        is_big: false,
        isType: null,
        hisShow: false,
        hisTypeList: [
          { type: null, value: "全部" },
          { type: "综合医院", value: "综合医院" },
          { type: "中医医院", value: "中医医院" },
          { type: "妇幼保健院", value: "妇幼保健院" },
          { type: "其他类型", value: "其他类型" },
        ],
        hisList2: []
      }
    },
    created() {
      this.changeTab(null)
    },
    methods: {
      changeTab(i) {
        this.isType = i;
        let data = {
          // hospitalProvince: this.HisTilter,
          hospitalType: i,
        };
        getHospitalList(data).then((res) => {
          if (res.code == 200) {
            this.hisList2 = res.rows.slice(0, 10);
            this.hisListCopy = JSON.parse(JSON.stringify(res.rows))
            this.hisShow = false
          }
        });
      },
      stretch(){
        this.hisShow = !this.hisShow
        if(this.hisShow == false){
          this.hisList2 = this.hisListCopy.slice(0, 10)
        }else{
          this.hisList2 = this.hisListCopy
        }
      },
      getDetails(hospitalId) {
        this.$router.push({
          path: "/serve",
          query: { activeName:2, hospitalId: hospitalId },
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .m_banner {
    width: 100%;
    height: 515px;
    background-image: url("../assets/img/mobile_bg9.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .m_banner_text {
      font-size: 42px;
      color: #fff;
      text-align: center;
      padding-top: 132px;
    }
    .m_banner_i {
      width: 45px;
      height: 6px;
      background-color: #fff;
      margin: 20px auto 16px;
    }
    .m_banner_text2 {
      font-size: 26px;
      color: #fff;
      text-align: center;
    }
  }
  .index_title {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    color: #000;
    margin: 40px auto;
    .index_title_span {
      margin: 0 20px;
    }
    .index_title_after {
      display: inline-block;
      width: 107px;
      height: 23px;
      background-image: url("../assets/img/titleIcon1.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transform: rotate(180deg);
    }
    .index_title_before {
      display: inline-block;
      width: 107px;
      height: 23px;
      background-image: url("../assets/img/titleIcon1.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  .index_subhead {
    text-align: center;
    font-size: 26px;
    font-weight: 400;
    color: #333;
    margin: 23px auto;
  }
  .index_more {
    width: 175px;
    height: 50px;
    line-height: 50px;
    border-radius: 4px;
    border: 2px solid #999999;
    font-size: 22px;
    color: #666;
    text-align: center;
    margin: 20px auto 0;
  }
  .ehp_m_box {
    padding: 37px;
    .ehp_m_img {
      width: 475px;
      display: block;
      margin: 49px auto;
    }
    .ehp_m_img2 {
      width: 607px;
      display: block;
      margin: 49px auto;
      margin-bottom: 0;
    }
    .ehp_m_img3 {
      width: 100%;
    }
    .ehp_m_introduce {
      font-size: 26px;
      font-weight: 400;
      color: #333333;
    }
    .modern_m_trait_list {
      padding: 43px 0;
      .modern_m_trait_item {
        display: flex;
        align-items: center;
        margin-bottom: 31px;
        .modern_m_trait_img {
          width: 91px;
          height: 91px;
        }
        .modern_m_trait_i {
          margin-left: 48px;
          .modern_m_trait_name {
            font-size: 26px;
            font-weight: bold;
            color: #1E1E1E;
            margin-bottom: 11px;
          }
          .modern_m_trait_introduce {
            font-size: 26px;
            font-weight: 400;
            color: #666666;
            line-height: 39px;
          }
        }
      }
    }
    .ehp_m_list {
      display: flex;
      width: 607px;
      margin: 10px auto;
      flex-wrap: wrap;
      .ehp_m_item {
        width: 50%;
        font-size: 20px;
        font-weight: 600;
        color: #333333;
        line-height: 35px;
        margin-bottom: 38px;
        img {
          width: 18px;
          height: 18px;
        }
      }
    }
    .ehp_m_but {
      font-size: 24px;
      font-weight: 400;
      color: #6D6D6D;
      text-align: center;
      padding-top: 30px;
    }
    .ehp_m_big {
      background: rgb(0 0 0 / 53%);
      z-index: 200;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
      img {
        width: 150%;
        margin-top: 40%;
        background: #fff;
      }
      .college_m_organization_close {
        color: #fff;
        font-size: 50px;
        float: right;
        margin: 20px;
      }
    }
  }
  .serve_m_box {
    background-color: #fff;
    padding: 50px 37px;
    .serve_m_type {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      padding: 0 60px;
      .serve_m_type_item {
        width: fit-content;
        padding: 21px 30px;
        background: #FFFFFF;
        border-radius: 10px;
        border: 1px solid #BFBFBF;
        font-size: 24px;
        font-weight: 400;
        color: #333333;
        margin-bottom: 20px;
      }
      .serve_m_type_isItem {
        color: #fff;
        border: 1px solid #2D43F2;
        background: #2D43F2;
      }
    }
  }
  .serve_m_hospital {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 41px;
    .serve_m_hospital_item {
      width: 48%;
      text-align: center;
      background: #FFFFFF;
      box-shadow: 0px 3px 16px 1px rgba(0,14,52,0.06);
      border-radius: 4px;
      border: 2px solid #E6E6E6;
      margin-bottom: 28px;
      padding: 21px;
      .serve_m_hospital_img {
        width: 138px;
        height: 138px;
      }
      .serve_m_hospital_name {
        font-size: 24px;
        font-weight: 400;
        color: #333333;
        padding-top: 20px;
      }
    }
  }
</style>

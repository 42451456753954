<template>
  <div>
    <div class="pc_ehp">
      <div class="banner-box">
        <div class="banner-boxs">
          <div class="banner-name">易策EHP医院绩效软件系统</div>
          <div class="banner-little-name">
            以预算为基础，工作量（DRGs&DIP）为依据，以绩效管理为抓手-促进医院“提质增效”
          </div>
          <div class="banner-bnt" @click="openPDF">下载资料</div>
        </div>
      </div>
      <div class="ehp-framework" v-scroll-reveal>
        <!-- 项目介绍 -->
        <div class="ehp-i">
          <div class="flex title2">
            <img class="titleIcon1" src="../assets/img/titleIcon1.png" />
            项目介绍
            <img
              class="titleIcon1 rotate180"
              src="../assets/img/titleIcon1.png"
            />
          </div>
          <div class="flex mes2 ehpMes2">
            <img src="../assets/img/titleIcon2.png" />
          </div>
          <div class="flex flexSB ehp-i-div">
            <img src="../assets/img/ehpImg1.png" alt="" />
            <div class="ehp-i-td">
              <p>
                基于工作量+业绩的综合效率预算的绩效分配模式，匹配最新的医保支付模式，通过配置自动采集有效数据，实现自由可控的绩效统计与核算。数据可视化大屏功能，充分发挥数据价值，帮助医院管理者快速找到影响医疗质量与运营效率的因素，高效精细化运营管理。
              </p>
              <p>
                易策EHP医院绩效软件系统包括:绩效数据核算模块、预算模块、业务科主任护士长模块、绩效考核模块、绩效发放模块、重点指标监控模块、绩效数据群体分析模块、绩效数据个体分析模块、月度经营分析报告、核算数据建立台账管理十大模块。
              </p>
            </div>
          </div>
        </div>
        <!-- 实施效果 -->
        <implementationMod :implementationData="implementationData" @relationUs="relationUs"/>
      </div>
      <!-- 产品特点 -->
      <div class="ehp-trait" v-scroll-reveal>
        <div class="flex title2">
          <img class="titleIcon1" src="../assets/img/titleIcon1.png" />
          产品特点
          <img class="titleIcon1 rotate180" src="../assets/img/titleIcon1.png" />
        </div>
        <div class="flex mes2">
          <img src="../assets/img/titleIcon2.png" />
        </div>
        <div class="flex flexS ehp-traitDiv">
          <img class="ehp-traitDiv-img" src="../assets/img/ehpImg6.png" />
          <div class="ehp-traitDiv-text">
            <div class="flex flexS">
              <img src="../assets/img/review_13.png" />支持医疗集团绩效考核
            </div>
            <div class="flex flexS">
              <img src="../assets/img/review_13.png" />实现绩效管理全流程管控
            </div>
            <div class="flex flexS">
              <img src="../assets/img/review_13.png" />以预算为基础，动态反馈
            </div>
            <div class="flex flexS">
              <img src="../assets/img/review_13.png" />智能监控数据，纠正纠偏
            </div>
          </div>
        </div>
      </div>
      <!-- 绩效软件系统架构 -->
      <div class="ehp-framework" v-scroll-reveal>
        <div class="flex title2">
          <img class="titleIcon1" src="../assets/img/titleIcon1.png" />
          绩效软件系统架构
          <img class="titleIcon1 rotate180" src="../assets/img/titleIcon1.png" />
        </div>
        <div class="flex mes2">
          <img src="../assets/img/titleIcon2.png" />
        </div>
        <img class="ehpImg7" src="../assets/img/ehpImg7.png" alt="" />
      </div>
      <!-- 服务累计 -->
      <serviceAcc :bg="2" />
      <!-- 项目专家 -->
      <expertItemMod />
      <relation :open="open" @openFun="openFun" />
    </div>
    <mobileEhp
      :implementationData="implementationData"
      class="mobile_ehp"
    />
  </div>
</template>

<script>
import implementationMod from "../components/implementationMod";
import serviceAcc from "../components/serviceAcc";
import expertItemMod from "../components/expertItemMod";
import relation from "../components/relation";
import mobileEhp from '@/mobileViews/ehp.vue'
export default {
  components: { implementationMod, serviceAcc, expertItemMod,relation, mobileEhp },
  data() {
    return {
      open:false,
      implementationData: {
        title: "实施效果",
        isBnt: true,
        list: [
          {
            img: require("../assets/img/modernImg9.png"),
            name: "技术服务",
            introduce: "体现技术服务特点，有利于医院的快速发展。",
          },
          {
            img: require("../assets/img/modernImg12.png"),
            name: "提升员工收益",
            introduce:
              "员工的绩效工资显著增加，绩效工资占员工收入的比重加大，激励性增强。",
          },
          {
            img: require("../assets/img/modernImg8.png"),
            name: "精准定制",
            introduce:
              "符合DRGs新医保的支付方式，医院的激励政策更具有针对性和导向性。",
          },
          {
            img: require("../assets/img/modernImg7.png"),
            name: "合理分配",
            introduce:
              "向临床一线人员倾斜，绩效工资分配实现多劳多得，调动了医务人员积极性。",
          },
          {
            img: require("../assets/img/ehpImg2.png"),
            name: "减少患者负担",
            introduce:
              "门诊次均费用和住院均次费用得到有效控制，不增加患者负担。",
          },
          {
            img: require("../assets/img/ehpImg5.png"),
            name: "完善制度",
            introduce:
              "建立了较为完善的绩效考核制度，明确了管理责任，落实了国考相关绩效指标。",
          },
          {
            img: require("../assets/img/modernImg10.png"),
            name: "全方面发展",
            introduce:
              "医院业务量增加，人均效率值提高，优势病种和专科得到倾斜和发展。",
          },
          {
            img: require("../assets/img/ehpImg3.png"),
            name: "强化“经营”",
            introduce:
              "科主任等中层人员的“经营”作用得到了强化，更有利于科主任发挥管理经营作用。",
          },
          {
            img: require("../assets/img/modernImg11.png"),
            name: "成本控制",
            introduce:
              "药品和耗材成本得到控制，医院收支出结构得到明显优化，医院的发展更可持续。",
          },
          {
            img: require("../assets/img/ehpImg4.png"),
            name: "解决难题",
            introduce:
              "帮助医院解决管理中的一些顽疾;管理更加高效，政令更加通畅。",
          },
        ],
      },
    };
  },
  methods: {
    /** 打开弹窗-组件内通知打开 */
    relationUs(bo) {
      this.open = bo;
    },
    /** 提交后关闭 */
    openFun(bo) {
      this.open = bo;
    },
    openPDF() {
      window.open(
        "http://yice-prod.oss-cn-guangzhou.aliyuncs.com/other/protalDown/%E6%98%93%E7%AD%96%E5%8C%BB%E7%AE%A1--%E6%98%93%E7%AD%96EHP%E5%8C%BB%E9%99%A2%E7%BB%A9%E6%95%88%E8%BD%AF%E4%BB%B6%E7%B3%BB%E7%BB%9F-%E7%BB%A9%E6%95%88%E8%BD%AF%E4%BB%B6%E7%B3%BB%E7%BB%9F.pdf"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-box {
  width: 100%;
  height: 400px;
  background-image: url("../assets/img/ehp.png");
  background-repeat: no-repeat;
  background-size: 100%;
  .banner-boxs {
    width: 1200px;
    margin: 0 auto;

    .banner-name {
      font-size: 52px;
      font-weight: 400;
      color: #fff;
      padding-top: 92px;
    }
    .banner-little-name {
      margin: 30px 0 50px 0;
      color: white;
    }
    .banner-bnt {
      width: 120px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #2d43f2;
      border-radius: 50px;
      color: #fff;
      font-size: 16px;
      margin-top: 48px;
      cursor: pointer;
    }
    .banner-bnt:hover {
      background: #4273ff;
    }
  }
}
.content-box {
  width: 1200px;
}
.ehp-i {
  padding-top: 70px;
  .ehp-i-div {
    width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
    img {
      width: 490px;
      height: 373px;
    }
  }
  .ehpMes2 {
    margin-bottom: 30px;
  }
  .ehp-i-td {
    width: 545px;
    height: 300px;
  }
  p {
    line-height: 28px;
  }
}
.ehp-trait {
  width: 1200px;
  margin: 0 auto;
  padding-top: 50px;
  .ehp-traitDiv {
    margin-top: -125px;
    .ehp-traitDiv-img {
      width: 835px;
      height: 662px;
    }
    .ehp-traitDiv-text {
      .flex {
        margin-bottom: 60px;
        img {
          width: 18px;
          height: 18px;
          margin-right: 18px;
        }
      }
    }
  }
}
.ehp-framework {
  background-image: url("../assets/img/review_bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  .ehpImg7 {
    width: 1200px;
    height: 1145px;
    display: block;
    margin: 0 auto;
    margin-top: -35px;
  }
}

.mobile_ehp {
  display: none;
}
@media screen and (max-width: 650px) {
  .mobile_ehp {
    display: block;
    background-color: #fff;
  }
  .pc_ehp {
    display: none;
  }
}
</style>
